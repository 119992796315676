<template>
  <div class="page-content">
    <div class="card row">
      <div class="card-body col-md-12">
        <div class="row d-flex justify-content-between">
          <div class="col-6">
            <el-input v-model="search.search_keyword" clearable size="small" prefix-icon="el-icon-search" placeholder="Search template"></el-input>
          </div>
          <div class="col-6">
            <el-button @click="$router.push({ name: 'Create Template' })" size="small" class="btn-primary float-right" icon="el-icon-plus">Create new template</el-button>
          </div>
        </div>
        <div class="table-responsive">
          <table class="table table-hover">
            <thead>
              <tr>
                <th>
                  <div class="d-flex align-items-center">
                    <span>Template Name</span>
                    <div class="d-flex flex-column ml-2 justify-content-center">
                      <font-awesome icon="chevron-up" class="cursor-pointer" :size="is_sort && sort_column === 'name' && sort_type === 'asc' ? 'md' : 'xs'" @click="shortByColumn('name', 'asc')"/>
                      <font-awesome icon="chevron-down" class="cursor-pointer" :size="is_sort && sort_column === 'name' && sort_type === 'desc' ? 'md' : 'xs'" @click="shortByColumn('name', 'desc')"/>
                    </div>
                  </div>
                </th>
                <th>
                  <div class="d-flex align-items-center">
                    <span>Category</span>
                    <div class="d-flex flex-column ml-2 justify-content-center">
                      <font-awesome icon="chevron-up" class="cursor-pointer" :size="is_sort && sort_column === 'category_str' && sort_type === 'asc' ? 'md' : 'xs'" @click="shortByColumn('category_str', 'asc')"/>
                      <font-awesome icon="chevron-down" class="cursor-pointer" :size="is_sort && sort_column === 'category_str' && sort_type === 'desc' ? 'md' : 'xs'" @click="shortByColumn('category_str', 'desc')"/>
                    </div>
                  </div>
                </th>
                <th>
                  <div class="d-flex align-items-center">
                    <span>Preview</span>
                    <div class="d-flex flex-column ml-2 justify-content-center">
                      <font-awesome icon="chevron-up" class="cursor-pointer" :size="is_sort && sort_column === 'body_content_truncate' && sort_type === 'asc' ? 'md' : 'xs'" @click="shortByColumn('body_content_truncate', 'asc')"/>
                      <font-awesome icon="chevron-down" class="cursor-pointer" :size="is_sort && sort_column === 'body_content_truncate' && sort_type === 'desc' ? 'md' : 'xs'" @click="shortByColumn('body_content_truncate', 'desc')"/>
                    </div>
                  </div>
                </th>
                <th>Language</th>
                <th>Divisions</th>
                <th>Created Date</th>
                <th>
                  <div class="d-flex align-items-center">
                    <span>Status</span>
                    <div class="d-flex flex-column ml-2 justify-content-center">
                      <font-awesome icon="chevron-up" class="cursor-pointer" :size="is_sort && sort_column === 'status' && sort_type === 'asc' ? 'md' : 'xs'" @click="shortByColumn('status', 'asc')"/>
                      <font-awesome icon="chevron-down" class="cursor-pointer" :size="is_sort && sort_column === 'status' && sort_type === 'desc' ? 'md' : 'xs'" @click="shortByColumn('status', 'desc')"/>
                    </div>
                  </div>
                </th>
                <th>#</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in computedTemplateList" :key="index">
                <td>{{ item.name }}</td>
                <td>{{ item.category_str || "-" }}</td>
                <td class="preview-body">{{ item.body_content_truncate }}</td>
                <td>
                  <el-popover
                    placement="right"
                    width="400"
                    trigger="hover">
                    <h6 v-if="item.header_content" class="mb-2">Header</h6>
                    <p v-if="item.header_content" class="mb-2">
                      {{ item.header_content.text || item.header_content.type_str }}
                    </p>
                    <h6 class="mb-2">Body</h6>
                    <p class="mb-2" style="white-space: pre-line; overflow-wrap: break-word;">{{ item.body_content }}</p>
                    <template v-if="item.footer_content">
                      <h6 class="mb-2">Footer</h6>
                      <p class="mb-2" style="white-space: pre-line; overflow-wrap: break-word;">{{ item.footer_content.text }}</p>
                    </template>
                    <h6 v-if="item.button_content_mapped" class="mb-2">Buttons</h6>
                    <p class="mb-2">
                      <span v-for="(btn, ibtn) in item.button_content_mapped" :key="ibtn">
                        <font-awesome :icon="btn.icon"/>&nbsp; {{ btn.text }} ({{btn.type_str}})<br/>
                      </span>
                    </p>
                    <span @click="visible = !visible" slot="reference" style="padding: 6px" class="badge ml-1 badge-secondary cursor-pointer">
                      <flag :iso="item.flag_icon"/> {{ item.language.toUpperCase() }}
                    </span>
                  </el-popover>
                </td>
                <td>
                  <span v-if="item.template_groups.length < 10">
                    <span v-for="item1 in item.template_groups" :key="item1.id" class="badge badge-secondary mr-2">
                      {{ item1.member_group.name }}
                    </span>
                  </span>
                  <span v-else>
                    <span v-for="item1 in item.template_groups.slice(0, 9)" :key="item1.id" class="badge badge-secondary mr-2">
                      {{ item1.member_group.name }}
                    </span>
                    <small>And {{ item.template_groups.length - 10 }} more</small>
                  </span>
                </td>
                <td>
                  {{ item.created_at_str }}
                </td>
                <td><span style="padding: 6px" class="badge ml-1" :class="item.status_color">{{ item.status }}</span></td>
                <td>
                  <button :disabled="item.status.toLowerCase() == 'pending'" class="btn btn-outline-primary btn-sm mr-2" @click="handlerClickEditTemplate(item)">
                    <font-awesome icon="edit"/>
                  </button>
                  <button class="btn btn-outline-secondary btn-sm mr-2" @click="handlerChangeDivisions(item)">
                    <font-awesome icon="users"/>
                  </button>
                  <button class="btn btn-outline-danger btn-sm" @click="confirmDeleteTemplate(item)">
                    <font-awesome icon="trash"/>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <el-empty v-if="!templateList.length" description="No templates"></el-empty>
        </div>
      </div>
    </div>
    <b-modal v-model="modal_change_divisions" title="Change Divisions" hide-footer @hide="onHideChangeDivisions">
      <b-form @submit.prevent="onSubmitChangeDivisions" @reset="onHideChangeDivisions">
        <b-form-group label="Divisions:">
          <el-select filterable :filter-method="handlerSearchDivisions" v-model="form_change_divisions.groups_id" multiple size="small" class="w-100">
            <el-option v-for="item in divisions_list" :key="item.id" :value="item.id" :label="item.name" :disabled="item.disabled"></el-option>
          </el-select>
        </b-form-group>
        <div class="d-flex flex-row-reverse">
          <b-button type="submit" variant="primary" class="ml-2">{{ $t('general.submit') }}</b-button>
          <b-button type="reset" variant="secondary">{{ $t('general.cancel') }}</b-button>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>
<script>
import { upperFirst } from 'lodash';
import moment from 'moment';
import channelsAPI from '@/api/channels';
import notifApi from '@/api/notifications';
import popupErrorMessages from '@/library/popup-error-messages';
import divisions from '../../../api/divisions';

export default {
  name: 'BroadcastTemplates',
  metaInfo: {
    title: 'Broadcast Templates',
  },
  data() {
    return {
      loaderStack: 0,
      loader: null,
      templateList: [],
      visible: false,
      page: 1,
      relation: {
        am: 'am',
        ar: 'sy',
        az: 'az',
        bn: 'bd',
        be: 'be',
        ca: 'es-ca',
        cs: 'cz',
        de: 'de',
        el: 'gr',
        en: 'gb',
        es: 'es',
        et: 'et',
        fa: 'ir',
        fr: 'fr',
        bg: 'bg',
        ha: 'ng',
        hi: 'in',
        hu: 'hu',
        hy: 'am',
        it: 'it',
        ja: 'jp',
        jv: 'id',
        km: 'km',
        ko: 'kr',
        lv: 'lv',
        mr: 'in',
        ms: 'my',
        pl: 'pl',
        pt: 'pt',
        ro: 'ro',
        ru: 'ru',
        sw: 'ke',
        ta: 'lk',
        te: 'in',
        th: 'th',
        tr: 'tr',
        uk: 'ua',
        uz: 'uz',
        vi: 'vn',
        zh: 'cn',
      },
      search: {
        search_keyword: '',
      },
      divisions_list: [],
      form_change_divisions: {
        groups_id: [],
      },
      division_search: {
        search_by: 'name',
        search_keyword: '',
      },
      modal_change_divisions: false,
      timeout_division_search: null,
      selected_template: {},
      is_sort: false,
      sort_type: 'asc',
      sort_column: '',
    };
  },
  computed: {
    activeWorkspace() {
      return this.$store.state.workspace.activeWorkspace;
    },
    activeChannel() {
      return this.$store.state.channels.activeChannel;
    },
    computedTemplateList() {
      if (!this.search.search_keyword) {
        return this.templateList;
      }
      return this.templateList.filter((v) => {
        const regex = new RegExp(this.search.search_keyword, 'ig');
        return regex.test(v.name);
      });
    },
  },
  mounted() {
    if (!this.activeChannel._id || !this.activeWorkspace._id || this.activeChannel.workspace_id !== this.activeWorkspace._id) {
      this.$router.push('/broadcast-templates');
      return;
    }
    this.getList();
  },
  methods: {
    async getListDivisions() {
      this.showLoader();
      const options = {
        search_by: this.division_search.search_by.toString(),
        search_keyword: this.division_search.search_keyword.toString(),
        ids: this.form_change_divisions.groups_id,
      };
      const response = await divisions.getList(options);
      popupErrorMessages(response);
      const { data } = response;
      this.divisions_list = data.rows.map((v) => {
        v.created_at_str = moment(v.created_at).format('DD MMM YYYY, HH:mm');
        return v;
      });
      this.hideLoader();
    },
    showLoader() {
      if (this.loaderStack === 0) {
        this.loader = this.$loading.show();
      }
      this.loaderStack += 1;
    },
    hideLoader() {
      this.loaderStack -= 1;
      if (this.loaderStack === 0) {
        this.loader.hide();
      }
    },
    dynamicSort(property, type = 'asc') {
      let sortOrder = 1;
      if (property[0] === '-') {
        sortOrder = -1;
        // eslint-disable-next-line no-param-reassign
        property = property.substr(1);
      }
      return (a, b) => {
        let result;
        if (type === 'asc') {
          // eslint-disable-next-line no-nested-ternary
          result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0; // asc
        } else if (type === 'desc') {
          // eslint-disable-next-line no-nested-ternary
          result = (a[property] < b[property]) ? 1 : (a[property] > b[property]) ? -1 : 0; // desc
        }
        return result * sortOrder;
      };
    },
    shortByColumn(column, type) {
      this.is_sort = true;
      this.sort_column = column;
      this.sort_type = type;
      if (this.templateList.length > 0) {
        this.templateList.sort(this.dynamicSort(column, type));
      }
    },
    async getList() {
      this.showLoader();
      await channelsAPI.templateList({
        workspace_id: this.activeWorkspace._id,
        channel_id: this.activeChannel._id,
      }).then((response) => {
        this.templateList = response.data.templates.map((v) => {
          v.created_at_str = moment(v.created_at).format('DD MMM YYYY, HH:mm');
          v.status_color = 'badge-success';
          if (!v.status) {
            v.status = 'APPROVED';
          }
          if (v.status.toLowerCase() === 'rejected') {
            v.status_color = 'badge-danger';
          } else if (v.status.toLowerCase() === 'pending') {
            v.status_color = 'badge-warning';
          }
          v.header_content = v.components.find((c) => c.type.toLowerCase() === 'header');
          if (v.header_content) {
            v.header_content.type_str = upperFirst(v.header_content.format.toLowerCase());
            v.header_content.icon = v.header_content.type_str.toLowerCase();
            if (v.header_content.type_str.toLowerCase() === 'document') {
              v.header_content.icon = 'file-alt';
            } else if (v.header_content.type_str.toLowerCase() === 'text') {
              v.header_content.icon = 'paragraph';
            }
          }

          v.body_content = v.components.find((c) => c.type.toLowerCase() === 'body').text;
          v.body_content_truncate = v.body_content;
          if (v.body_content.length > 35) {
            v.body_content_truncate = `${v.body_content.substr(0, 32)}...`;
          }

          v.footer_content = v.components.find((c) => c.type.toLowerCase() === 'footer');

          v.button_contents = v.components.find((c) => c.type.toLowerCase() === 'buttons');
          if (v.button_contents) {
            v.button_content_mapped = v.button_contents.buttons.map((btn) => {
              if (btn.type.toLowerCase() === 'phone_number') {
                btn.type_str = 'Phone Number';
                btn.icon = 'phone-alt';
              } else if (btn.type.toLowerCase() === 'url') {
                btn.type_str = 'Website URL';
                btn.icon = 'external-link-alt';
              } else if (btn.type.toLowerCase() === 'quick_reply') {
                btn.type_str = 'Quick Reply';
                btn.icon = 'comment';
              } else if (btn.type.toLowerCase() === 'otp') {
                btn.type_str = 'OTP Button';
                btn.icon = 'copy';
              }
              return btn;
            });
          }

          v.flag_icon = this.relation[v.language.split('_')[0]];
          if (!v.flag_icon) {
            v.flag_icon = v.language.split('_')[0];
          }
          if (v.category) v.category_str = v.category.split('_').map((c) => upperFirst(c.toLowerCase())).join(' ');
          return v;
        });
      }).catch((err) => {
        console.log(err);
      });
      this.hideLoader();
    },
    confirmDeleteTemplate(item) {
      this.$confirm(this.$t('templates.confirm.delete'), this.$t('general.confirmation'), {
        confirmButtonText: this.$t('general.yes'),
        cancelButtonText: this.$t('general.no'),
        type: 'warning',
        center: true,
        /* eslint-disable no-param-reassign */
        beforeClose: async (action, instance, cb) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true;
            try {
              const response = await notifApi.deleteTemplate({
                workspace_id: this.activeWorkspace._id,
                channel_id: this.activeChannel._id,
                template_name: item.name,
              });
              await popupErrorMessages(response);
              this.$message({
                message: this.$t('labels.success.delete'),
                type: 'success',
              });

              this.page = 1;
              this.search = '';
              this.getList();
            } catch (error) {
              console.log(error);
            }

            instance.confirmButtonLoading = false;
            cb();
            return;
          }
          cb();
        },
      });
    },
    handlerClickEditTemplate(item) {
      this.$router.push({
        name: 'Edit Broadcast Templates WhatsApp',
        params: {
          id: item.id,
        },
      });
    },
    handlerChangeDivisions(item) {
      /* eslint-disable prefer-object-spread */
      this.selected_template = Object.assign({}, item);
      this.form_change_divisions = {
        groups_id: item.template_groups.map((v) => v.member_group_id),
      };
      this.getListDivisions();
      this.modal_change_divisions = true;
    },
    async onSubmitChangeDivisions() {
      this.showLoader();
      const response = await notifApi.updateTemplateDivisions({
        workspace_id: this.activeWorkspace._id,
        channel_id: this.activeChannel._id,
        template_id: this.selected_template.id,
        data: this.form_change_divisions,
      });
      await popupErrorMessages(response);
      this.$message({
        type: 'success',
        message: 'Successfully change template divisions',
      });
      this.onHideChangeDivisions();
      this.getList();
      this.hideLoader();
    },
    handlerSearchDivisions(value) {
      clearTimeout(this.timeout_division_search);
      this.timeout_division_search = setTimeout(() => {
        this.division_search.search_keyword = value;
        this.getListDivisions();
      }, 1000);
    },
    onHideChangeDivisions() {
      this.form_change_divisions = {
        groups_id: [],
      };
      this.modal_change_divisions = false;
      this.selected_template = {};
    },
  },
};
</script>
<style>
/* .preview-body {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
} */
</style>
